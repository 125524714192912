<div class="card bg-white border-0 p-3 customMargin" >
    <div class="card-doc-title ml-3 row">
<i class="fa-solid fa-right-left mr-2 fa-lg mt-2"></i>
          {{'languages.affaire.transactionsFinanciere' | translate}}
    </div>
    <button type="button" class="btn-document-add btn-position-add" (click)="emptyTransaction()" [attr.data-title]="'languages.affaire.ajoutTransaction' | translate" data-toggle="modal" data-target="#AjoutTransaction">
        <i class="fa-solid fa-plus fa-lg"></i>
      </button>
 
        <div class="p-3" >
            <div class="list-group-item-header">
                <div class="row text-center">
                    <div class="col-2">  {{'languages.listing.Type' | translate}}</div>
                    <div class="col-2">  {{'languages.opportunite.details.date' | translate}}</div>
                    <div class="col-2">  {{'languages.affaire.Montant' | translate}} ( {{currency}} )</div>
                    <div class="col-2  "> {{'languages.affaire.Motif' | translate}}</div>
                    <div class="col-3  "> {{'languages.affaire.modePaiment' | translate}}</div>
                    <div class="col-1  ">  {{'languages.opportunite.details.fichier' | translate}}</div>

                    

                </div>
            </div>
            <div *ngIf="show_loading" class="row d-flex justify-content-center align-items-center mb-3 mt-3">
                <div class="text-center m-auto">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
              </div>
              <div *ngIf="listtransactions.length >0 && !show_loading">
                <ul class="list-group mt-1 " 
                *ngFor="let transaction of listtransactions"  >
                    <li class="list-group-item d-flex justify-content-between align-items-center " [ngClass]="{'credit': transaction.type==='Credit','debit': transaction.type==='Debit'}">
                        <div class="row w-100   d-flex align-items-center  text-center">
                            <div class="col-2 d-flex" [ngClass]="{'creditText': transaction.type==='Credit','debitText': transaction.type==='Debit'}">
                        <div class="col-4">
                            <img src="assets/icons/debit.svg"  *ngIf=" transaction.type==='Debit'">
                            <img src="assets/icons/credit.svg"   *ngIf=" transaction.type==='Credit'">
                        </div>
                           <div class="col-2">
                            {{transaction.type}}
                           </div>

                               
                             </div>
                            <div class="col-2 ">
                               
                                {{extractDate(transaction?.date)}}</div>
                            <div class="col-2 "> {{transaction.type==='Credit'? "+ " :"- "}} {{ transaction?.montant |currency:currency:'symbol':formatCurrency:'fr-FR'}}</div>
                            <div class="col-2 " title="{{transaction?.motif}}">{{transaction.motif ? capitalizeFirstLetter(expandReason(transaction.motif)): "-"}}</div>
                            <div class="col-3 " title="{{transaction?.mode_paiement}}">{{transaction.mode_paiement ? capitalizeFirstLetter(expandReason(transaction.mode_paiement)): "-"}}</div>
                                <div class="col-1 ">
                                    
                                    <a *ngIf="transaction?.file?.url" target="_blank" href="{{transaction?.file?.url}}"   [ngClass]="{'creditText': transaction.type==='Credit','debitText': transaction.type==='Debit'}">
                                      
                                            <i class="fa-solid fa-file-pdf fa-icon-size"></i>
                                        

                                    </a>
                                    <p class="m-0" *ngIf="!transaction?.file?.url">-</p>


                                    
                            </div>
                         
                            
                              
                              
                              
                              
                              
                            
                        </div>
                    
                    </li>
                </ul>
            </div>
            <div *ngIf="listtransactions.length < 1 && !show_loading" class="row p-3"> 
                <div class="col-12  d-flex justify-content-center">
                   <img src="assets/icons/nodataIcon.svg">
                  </div>
                  <div class="col-12  d-flex justify-content-center">
                    <p class="noData"> {{'languages.affaire.noDataAvailable' | translate}}</p>
                  </div>
            </div>
        </div>
    <div  *ngIf="totalPages> 0" class="d-flex align-items-center justify-content-center containerTache" class="pagination" >
        <nav aria-label="Page navigation example" *ngIf="listtransactions?.length > 0">
            <ul class="pagination pg-blue">
                <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                    <a class="page-link-symbol">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                    <a class="page-link-symbol-chevron" aria-label="Previous">
                        <i class="fa-solid fa-chevron-left fa-lg"></i>
                    </a>
                </li>
                <ng-container *ngFor="let item of [].constructor(3); let i = index">
                    <li *ngIf="i+page-3>0" class="page-item">
                        <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                            3}}</a>
                    </li>
                </ng-container>
                <ng-container *ngFor="let item of [].constructor(3); let i = index">
                    <li *ngIf="i+page<totalPages" class="page-item">
                        <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                            [ngClass]="{'active-page':page==(i+page)}">
                            {{i + page}}
                        </a>
                    </li>
                </ng-container>
                <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                <li class="page-item">
                    <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                        [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                </li>
                <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                    <a class="page-link-symbol-chevron" aria-label="Next">
                        <i class="fa-solid fa-chevron-right fa-lg"></i>
                    </a>
                </li>
                <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                    <a class="page-link-symbol">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
        </div>
</div>


<!-- Consulter Modal transaction--------------------------------------------------------------------------------- -->

  
<div class="modal fade" id="transactionrModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content modal-radius">
            <form #myForm="ngForm">
                <div class="modal-header modal-header text-center">
                    <div class="row w-100">
                        <div class="col-3">

                        </div>
                        <div class="col-6">
                            <p class="text-center title justify-content-center">
                              {{'languages.affaire.detailTransaction' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                           >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">

                    <div class="conatainer-modal-elements">


                        <div class="border-1">
                            <json-editor [options]="editorOptions" [data]="transaction?.detail"></json-editor>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <button class="btn-dt-cancel-popup" type="button" data-dismiss="modal" aria-label="Close" #ClosePopup>
<i class="fa-solid fa-circle-xmark fa-lg mr-1"></i>
                        {{'languages.buttons.fermer' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- add Modal transaction--->

<div class="modal fade"   id="AjoutTransaction" tabindex="-1" role="dialogAdd" aria-labelledby="exampleModalLabell" >
    <div class="modal-dialog model-xl modall" role="dialogAdd">
        <div class="modal-content modalAdd" >
            <form  #myForm="ngForm">
                <div class="modal-header">
                    <h2 class="text-center title justify-content-center m-0 w-100" id="exampleModalLabel">{{'languages.affaire.ajoutTransactionTitle' | translate}}</h2>
                    <button #closeModal (click)="emptyTransaction()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                   
                </button>
                </div>
                <div class="modal-body">

                    <div class="conatainer-modal-elements">
                        <div class="row">
                            <div class="col-6 p-2">
                                <mat-form-field class="w-100" appearance="standard">
                                    <mat-label>{{'languages.listing.Type' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="transactionAdd.type" #matRe required #type="ngModel" placeholder="{{'languages.affaire.selectTypeTransaction' | translate}}" name="type" (selectionChange)="GetMotifTransactions()">
                                        <mat-option *ngFor="let type of typeTransaction" [value]="type.type">
                                            {{type.libelle}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="type.invalid && (type.dirty || type.touched)" >
                                        <div *ngIf="type.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.affaire.typeOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                                
                            </div>
                            <div class="col-6 p-2">
                                <mat-form-field class="w-100" appearance="standard">
                                    <mat-label>{{'languages.affaire.transactionDate' | translate}}</mat-label>
                                    <input matInput name="date_creation" #date_creation="ngModel" required [(ngModel)]="transactionAdd.date" placeholder="{{'languages.affaire.transactionDate' | translate}}"  [matDatepicker]="creation">

                                    <mat-datepicker-toggle matSuffix [for]="creation"></mat-datepicker-toggle>
                                    <mat-datepicker #creation></mat-datepicker>
                                    <mat-error *ngIf="date_creation.invalid && (date_creation.dirty || date_creation.touched)" >
                                        <div *ngIf="date_creation.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.affaire.dateTransactionOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 p-2 " *ngIf=" transactionAdd?.type==='Credit'">
                                <mat-form-field class="w-100" appearance="standard">
                                    <mat-label> {{'languages.affaire.modePaiment' | translate}}</mat-label>
                                    <mat-select name="Modepayemnt" placeholder=" {{'languages.affaire.selectPaymentMethode' | translate}}" required #Modepayemnt="ngModel" [(ngModel)]="transactionAdd.mode_Payment">
                                        <mat-option *ngFor="let paiement of allPaiement" [value]="paiement.id">
                                            {{paiement.libelle}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="Modepayemnt.invalid && (Modepayemnt.dirty || Modepayemnt.touched)" >
                                        <div *ngIf="Modepayemnt.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.affaire.modePaymentOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 p-2 ">
                                <mat-form-field class="w-100" appearance="standard">
                                    <mat-label>{{'languages.affaire.Montant' | translate}} ( {{currency}} )</mat-label>
                                    <input matInput 
                                    [(ngModel)]="transactionAdd.Montant"
                                    (change)="convertCurreny($event)" 
                                    type="number" 
                                    required
                                    #Montant="ngModel"
                                    name="Montant"
                                     placeholder="{{'languages.affaire.Montant' | translate}}" 
                                     min="0"
                                      appNotNegative>
                                      <mat-error *ngIf="Montant.invalid && (Montant.dirty || Montant.touched)" >
                                        <div *ngIf="Montant.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.affaire.montantOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            
                        
                            <div class="col-6 p-2 ">
                                <mat-form-field class="w-100" appearance="standard">
                                    <mat-label> {{'languages.affaire.Motif' | translate}}</mat-label>
                                    <mat-select name="Motif" placeholder="{{'languages.affaire.selectMotif' | translate}}" required #Motif="ngModel" [(ngModel)]="transactionAdd.motif">
                                        <mat-option *ngFor="let motif of listMotifTransaction" [value]="motif.code">
                                            {{motif.libelle}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="Motif.invalid && (Motif.dirty || Motif.touched)" >
                                        <div *ngIf="Motif.errors.required">
                                            <i class="fa-solid fa-triangle-exclamation"></i>
                                            {{'languages.alerts.motifOblig' | translate}}
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 w-100">
                                <div class="form-group mt-2">
                                    <label for="fileField" class="attachment">
                          <div class=" btn-file mt-1">
                            <div class="btn-file__actions mt-1">
                              <div class="btn-file__actions__item mt-1 col-xs-12 text-center " id="file-upload-container">
                                <div class="btn-file__actions__item--shadow mt-1">
            
            <div class="row d-flex justify-content-center align-items-center ">
                                  
                                  <i class="fa-solid fa-upload fa-icon-size mr-2"></i>
                                  <p class="import m-0">{{'languages.affaire.importFile' | translate}}</p>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input (change)="onFileChange($event) " accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .zip, .odt, .txt"  name="file" type="file" id="fileField">
                        </label>
                      
                                </div>
                                 <ng-container *ngIf="showLoaderDoc">
                                <div class="row mb-3 mt-3">
                                    <div class="text-center m-auto ">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </ng-container>

                                <div *ngIf="transactionAdd?.file && !showLoaderDoc" class="col-md-12 fileComment d-flex  align-items-center mb-2 py-2 " >

                                    <button  type="button" (click)="removefile()" class="deleteFile">
                                      <img  src='../../../assets/icons/deleteIcon.svg'>
                                      </button>
        
                                    
                                    <img  src='../../../assets/img/FileIcon.svg'>
        
        
                                   <span class="file-name  links ml-2">{{expandName(transactionAdd?.fileName)}}  </span>
        
                                </div>
                           
                            </div>
                        </div>
                          


                    </div>
                </div>
                
                    <div class="row d-flex justify-content-center w-100 mt-2 mb-3">
                        <div class="col-6">
                            <ng-container *ngIf="show_loading_adding">
                                <div class="row mb-3 mt-3">
                                    <div class="text-center m-auto ">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row text-center justify-content-center w-100">
                            <button type="button" class="btn-dt-save-popup mr-2" type="submit" (click)="InsertTransaction()">
                                <i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                                {{'languages.buttons.ajouter' | translate}}
                          </button>
                            <button data-dismiss="modal" aria-label="Close"  type="button" class="btn-dt-cancel-popup ml-2" #closeModal (click)="emptyTransaction()">
                                <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
                            {{'languages.buttons.annuler' | translate}}

                          </button>
                    
                           
                        
                    </div>
                    </div>


            </form>
        </div>
    </div>
</div>
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';
import { FilterBody } from '../../prospect-pro-form/prospect-pro-form.component';
import { ProspectCLientService } from 'src/app/services/prospect-client.service';
 

@Component({
  selector: 'app-prospect-filtred-list-modal',
  templateUrl: './prospect-filtred-list-modal.component.html',
  styleUrls: ['./prospect-filtred-list-modal.component.css'],
})
export class ProspectFiltredListModalComponent implements OnInit {
  list_prospects: any[] = []
  limit = 5;
  page = 1;
  totalPages = 0;
  totalLength: any;
  intervale = 1;
  filterBody: FilterBody = new FilterBody();
  loader: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private prospectCLientService: ProspectCLientService,
    private dialogRef: MatDialogRef<ProspectFiltredListModalComponent>
  ) {
    this.filterBody = data.filterBody;
  }

  ngOnInit(): void {
    this.get_list_prospects();
  }

  get_list_prospects() {
    this.prospectCLientService.getListProspectSociete(this.filterBody, this.page, this.limit).subscribe(
      (data) => {
        if(data.Total && data.Total>0){
        this.list_prospects = data.info;
      }
        this.loader = false;
        this.totalLength = data.Total?data.Total:0;
        this.calculate_nb_pages(this.totalLength);
      },
      (error) => {
        console.log(error);
        this.loader = false;
        this.list_prospects = [];
      }
    );
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    localStorage.setItem('page', this.page.toString());
    this.get_list_prospects();
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', this.page.toString());
    this.get_list_prospects();
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        this.get_list_prospects();
      }
    }
  }

  updateListProspect() {
    this.page = 1;
    this.get_list_prospects();
  }

  onClose() {
    this.dialogRef.close();
  }

  sendDataIndismiss(item) {
    this.dialogRef.close(item);
  }

  selectedItems: Set<number> = new Set<number>();
  toggleSelection(index: number): void {
    if (this.selectedItems.has(index)) {
      this.selectedItems.delete(index);
    } else {
      this.selectedItems.add(index);
    }
  }

  isSelected(index: number): boolean {
    return this.selectedItems.has(index);
  }
}

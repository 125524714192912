<div class="row w-100" *ngIf="modeAjout">
    <div  class="col-2  d-flex flex-column align-items-end ml-auto" >
        <div class="d-flex ">
            <mat-spinner class="mr-4 mt-1" [diameter]="25" *ngIf="loaderInfo" ></mat-spinner>
           <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false" (click)="showhidePopfirst()"  type="button"
               class="add-btn-searchOpp" >
               
               <i class="fa-solid fa-magnifying-glass fa fa-lg fa-flip-horizontal"></i>
               &nbsp;
                {{'languages.buttons.rechercherPar' | translate}}

           </button>
     
        <div class="dropdown-menu popup dropdown-menu-right" aria-labelledby="dropdownMenuButton"
            [ngClass]="{'hidden': !isDropdownVisible}"  >
            <div class="popup-content" (click)="stopClickPropagation($event)">
                <div class="row mb-2">
                    <button type="button" (click)="showhidePop()"
                        class="close-button">&times;</button>
                </div>
             
                <div class="row mt-1"
                    *ngFor="let form of formInfoHolder ;let index = index; let isFirst = first;">
                
                    <div class="input-group  col-10">
                        <select class="custom-select"  name="key{{index}}" id="key{{index}}" [(ngModel)]="formInfoHolder[index].key"   (ngModelChange)="setValues(index , 'key', $event)" >
                            <option [disabled]="item.disabled" value="{{item.name}}" *ngFor="let item of listkeys">{{item.title}}</option>
                        </select>
                            <select class="custom-select" name="op{{index}}" id="op{{index}}" [(ngModel)]="formInfoHolder[index].operateur" (ngModelChange)="setValues(index , 'operateur ', $event)" >
                                <option value="="> {{'languages.opportunite.egale' | translate}}</option>
                                <!-- <option value="!=">Different</option> -->
                            </select>
                        <input type="text" aria-label="Last name"
                            placeholder="{{'languages.prospect.enterValue' | translate}}" class="form-control "  name="val{{index}}" id="val{{index}}"
                            (change)="setValues(index , 'value', $event)"  />
                    </div>
                    <div class="col-2  d-flex align-items-center justify-content-start " *ngIf="isFirst">
                        <button type="button" class="btn-dt-add "
                            (click)="onAdd()">
                            <i class="fa-solid fa-plus fa-lg"></i>
                        </button>
                    </div>
                    <div class="col-2  d-flex align-items-center justify-content-start" *ngIf="!isFirst">
                        <button type="button" class=" cancelBtn float-left"
                            (click)="deletePrspect(index)">
                            <img src="assets/icons/closeIcon.svg" class="iconClose">
                        </button>
                    </div>
                </div>
                <!--          <mat-form-field class="w-100 mb-3">
                        <input matInput id="path{{index}}" name="path{{index}}"
                            [ngModel]="formInfoHolder[index]"
                            (change)="inputChange($event.target.value, index)" >
                       
                    </mat-form-field> -->

                <div class="row w-100 d-flex justify-content-center mt-4">
                    <div class=" mx-2  text-right ">
                        <button (click)=" OnOpen()" class=" btn-dt-save-popup"  data-toggle="modal" data-target="#listProspect" type="button">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                            {{'languages.buttons.rechercher' | translate}}
                        </button>
                        
                    </div>
                    <div class=" mx-2 text-right ">
                        <button class="btn-dt-cancel-popup" style="width:128px" type="button" (click)="clearModal()">
<i class="fa-solid fa-circle-xmark fa-lg mr-1"></i>
                                {{'languages.buttons.annuler' | translate}}
                        </button>
                    </div>
            
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="row" >
    
    <div class="col-12" [ngClass]="{'cover': !allowed_to_update}">

        <div class="row">

            <!--Identité-->
            <div class="d-flex">
                <div class="prospect-card-field" [ngClass]="!isfilled && loaderinfo==false ?  'col-xl-12 col-lg-12 ': 'col-xl-6 col-lg-12 '">
                    <form [formGroup]="prospectForm">
                        <div class="form-group-container infoCompBloc" [ngClass]="{'biggerCompBloc': (type==0 && !isMainProspect)||type==1}">
                            <p class="form-group-container-title"> {{"languages.prospect.identite" | translate }} </p>

                            <div class="row">
                                <!--Civilité-->
                                <div class="col-xl-3 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{"languages.prospect.Civilite" | translate }}</mat-label>
                                        <mat-select (selectionChange)="updateProspect2()" formControlName="civilite">
                                            <mat-option value="Mr">{{"languages.prospect.Mr" | translate }} </mat-option>
                                            <mat-option value="Mme">{{"languages.prospect.Mme" | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="prospectForm.controls['civilite'].invalid && (prospectForm.controls['civilite'].dirty || prospectForm.controls['civilite'].touched)">
                                        <div *ngIf="prospectForm.controls['civilite'].errors?.required">
                                           {{"languages.prospect.check_civilite" | translate }}
                                        </div>
                                    </div>
                                </div>
                                <!--Nom-->
                                <div class="col-xl-5 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{"languages.prospect.nom" | translate }} </mat-label>
                                        <input matInput type="text" required formControlName="name" (change)="updateProspect2()" autocomplete="off">
                                        <i class="fa-solid fa-user" matSuffix></i>
                                        <mat-error *ngIf="prospectForm.controls['name'].invalid && (prospectForm.controls['name'].dirty || prospectForm.controls['name'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['name'].errors?.required">
                                                {{"languages.prospect.check_nom" | translate }}
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Prénom-->
                                <div class="col-xl-4 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{"languages.prospect.prenom" | translate }}</mat-label>
                                        <input matInput type="text" required formControlName="surname" (change)="updateProspect2()" autocomplete="off">
                                        <i class="fa-solid fa-user" matSuffix></i>                                        <mat-error *ngIf="prospectForm.controls['surname'].invalid && (prospectForm.controls['surname'].dirty || prospectForm.controls['surname'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['surname'].errors?.required">
                                               {{"languages.prospect.check_prenom" | translate }}
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Relation (particulier)-->
                                <div *ngIf="type==0 && !isMainProspect" class="col-12">
                                   
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>{{"languages.prospect.relation" | translate }} </mat-label>
                                            <mat-select (selectionChange)="updateProspect2()" formControlName="relation" required>
                                                <mat-option value="Conjoint">{{"languages.prospect.Conjoint" | translate }} </mat-option>
                                                <mat-option value="Ascendant">{{"languages.prospect.Ascendant" | translate }} </mat-option>
                                                <mat-option value="Descendant">{{"languages.prospect.Descendant" | translate }} </mat-option>
                                                <mat-option value="Parrainage">{{"languages.prospect.Parrainage" | translate }} </mat-option>
                                                <mat-option value="Amitié">{{"languages.prospect.Amitie" | translate }} </mat-option>
                                                <mat-option value="Frère-Soeur">{{"languages.prospect.Frere_Soeur" | translate }} </mat-option>
                                                <mat-option value="Famille-autre">{{"languages.prospect.Famille_Autre" | translate }} </mat-option>
                                                <mat-option value="Collègues">{{"languages.prospect.Collegues" | translate }} </mat-option>
                                                <mat-option value="Doublon">{{"languages.prospect.Doublon" | translate }} </mat-option>
                                                <mat-option value="Colocataires">{{"languages.prospect.Colocataires" | translate }} </mat-option>
                                                <mat-option value="Famille-Autre">{{"languages.prospect.Famille_Autre" | translate }} </mat-option>
                                                <mat-option value="Voisinage">{{"languages.prospect.Voisinage" | translate }}</mat-option>
                                                <mat-option value="coupe" *ngIf="modeupdateProspect">{{"languages.prospect.Coupe" | translate }} </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="prospectForm.controls['relation'].invalid && (prospectForm.controls['relation'].dirty || prospectForm.controls['relation'].touched)">
                                                <mat-error *ngIf="prospectForm.controls['relation'].errors?.required">
                                                   {{"languages.prospect.check_relation" | translate }}
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    
                                </div>
                                <!--Poste/Fonction (pro)-->
                                <div *ngIf="type==1" class="col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{"languages.prospect.Poste-Fonction" | translate }} </mat-label>
                                        <input matInput type="text" formControlName="post" (change)="updateProspect2()" autocomplete="off">
                                        <mat-error *ngIf="prospectForm.controls['post'].invalid && (prospectForm.controls['post'].dirty || prospectForm.controls['post'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['post'].errors?.required">
                                                {{"languages.prospect.check_fonction" | translate }}
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Date de Naissance-->

                                    <div class="col-xl-6 col-lg-12">
                                        <mat-form-field class="full-width" appearance="standard">

                                            <mat-label>{{"languages.prospect.birthDate" | translate }}</mat-label>
                                            <input placeholder="jj/mm/aaaa" (click)="picker.open()" (dateChange)="updateProspect2()" matInput [matDatepicker]="picker" [min]="minDate"  [max]="dateNow" formControlName="DN">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>

                                    </div>
                                    <!--     <div class="col-xl-6 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{countryLanguage.PROSPECT.SOCIAL_SECURITY.LABEL}}</mat-label>
                                        <input matInput type="text" formControlName="securite_sociale" name="securite_sociale" (change)="updateProspect2()" autocomplete="off">
                                        <mat-error *ngIf="prospectForm.controls['securite_sociale'].invalid && (prospectForm.controls['securite_sociale'].dirty || prospectForm.controls['securite_sociale'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['securite_sociale'].errors?.pattern">
                                                {{countryLanguage.PROSPECT.SOCIAL_SECURITY.LABEL}} invalide doit contenir {{countryLanguage.PROSPECT.SOCIAL_SECURITY.PLACEHOLDER.length}} chiffres
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div> -->
                                    <!--Situation Familiale-->
                                    <div class="col-xl-6 col-lg-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>{{"languages.prospect.SituationFamiliale" | translate }} </mat-label>
                                            <mat-select (selectionChange)="updateProspect2()"formControlName="situation">
                                                <mat-option value="N.R">{{"languages.prospect.NR" | translate }} </mat-option>
                                                <mat-option value="Marié(e)">{{"languages.prospect.marie" | translate }} </mat-option>
                                                <mat-option value="Divorcé(e)">{{"languages.prospect.divorce" | translate }} </mat-option>
                                                <!-- <mat-option value="Pacsé(e)">Pacsé(e)</mat-option> -->
                                                <mat-option value="Célibataire">{{"languages.prospect.celibataire" | translate }} </mat-option>
                                                <mat-option value="Veuf/Veuve">{{"languages.prospect.Veuf-Veuve" | translate }} </mat-option>
                                                <mat-option value="Concubinage">{{"languages.prospect.Concubinage" | translate }} </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>

                                <!--Nombre d'Enfants-->
                                <div class="col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{"languages.prospect.kidsNumber" | translate }}</mat-label>
                                        <input (keypress)="numberOnly($event)" (change)="updateProspect2()" min="0" type="number" max="11" matInput placeholder="0" formControlName="nb_enfants">
                                        <i class="fa-solid fa-user" style="margin-left: 10px;" matSuffix></i>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </form>
                 </div>

            <!--info complementaires-->
            <div  *ngIf="loaderinfo" class="col-xl-6 col-lg-12 prospect-card-field" style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner *ngIf="loaderinfo" [diameter]="25"></mat-spinner>
                </div>
            <div  *ngIf="isfilled && loaderinfo==false" class="col-xl-6 col-lg-12 prospect-card-field">
                <div class="form-group-container infoCompBloc" id="infoComp" [ngClass]="{'biggerCompBloc': (type==0 && !isMainProspect)||type==1}" >
                    <p class="form-group-container-title"> {{"languages.prospect.infoComp" | translate }} </p>
                    <div class="d-flex justify-content-center">
                        <mat-spinner *ngIf="loadingFormBuilder" [diameter]="25"></mat-spinner>
                    </div>
                    <form  [formGroup]="form" *ngIf="!loadingFormBuilder" (change)="updateProspect2()">
                      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (modelChange)="formModelChange($event)"></formly-form>
                    </form>
                </div>
            </div>
            </div>
            <!--Contact-->
            <div class="col-xl-6 col-lg-12 prospect-card-field">
                <form [formGroup]="prospectForm">
                    <div class="form-group-container">
                        <p class="form-group-container-title"> {{"languages.prospect.contact" | translate }}  </p>
                        <div class="row">
                            <!--Email-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.email" | translate }}</mat-label>
                                    <input matInput type="email" placeholder="email@email.com" (change)="updateProspect2()" formControlName="adresse_mail" name="adresse_mail" [required]="isRequired" autocomplete="nofill">
                                    <mat-error *ngIf="prospectForm.controls['adresse_mail'].invalid && (prospectForm.controls['adresse_mail'].dirty || prospectForm.controls['adresse_mail'].touched)">
                                        <mat-error *ngIf="prospectForm.controls['adresse_mail'].errors?.required">
                                           {{"languages.prospect.check_emailOblig" | translate }}
                                        </mat-error>
                                        <mat-error *ngIf="prospectForm.controls['adresse_mail'].errors?.pattern">
                                            {{"languages.prospect.check_emailInvalide" | translate }}
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--Mobile-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label class="ng2tel-field-label">{{"languages.prospect.Mobile" | translate }}</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" (change)="updateProspect2()" name="mobile" class="full_width" maxlength="14" formControlName="mobile" [ng2TelInputOptions]="{initialCountry: telCountry}"
                                        (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'mobile')">
                                    <i class="fa-solid fa-mobile" matSuffix></i>
                                </mat-form-field>

                            </div>
                            <!--Téléphone-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label class="ng2tel-field-label">{{"languages.prospect.phone" | translate }}</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" (change)="updateProspect2()" name="tel" class="full_width" maxlength="14" formControlName="tel" [ng2TelInputOptions]="{initialCountry: telCountry}"
                                        (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'tel')">
                                    <i class="fa-solid fa-phone-flip float-right"></i>
                                </mat-form-field>
                            </div>
                            <!--2ème Téléphone-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label class="ng2tel-field-label">{{"languages.prospect.phone2" | translate }}</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" (change)="updateProspect2()" name="tel2" class="full_width" maxlength="14" formControlName="tel2" [ng2TelInputOptions]="{initialCountry: telCountry}"
                                        (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'tel2')">
                                    <i class="fa-solid fa-phone-flip float-right"></i>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <!--Adresse-->
            <div class="col-xl-6 col-lg-12 prospect-card-field">
                <form [formGroup]="prospectForm">
                    <div class="form-group-container">
                        <p class="form-group-container-title"> {{"languages.prospect.Adresse" | translate }} </p>
                        <div class="row">
                            <!--Adress Auto Google API-->
                            <div class="col-12">
                                <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update" iconColor="#9b9b9b" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                                </app-auto-complete-adresses>
                            </div>
                            <!--Country-->
                            <div class="col-6">
                                <mat-select-country appearance="standard" label="{{ 'languages.prospect.pays' | translate }}" [disabled]="!allowed_to_update" class="full-width" [value]="defaultCountry" [itemsLoadSize]="5" (onCountrySelected)="onCountrySelected($event)">
                                </mat-select-country>
                            </div>
                            <!--Ville-->
                            <div class="col-6">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.Ville" | translate }}</mat-label>
                                    <input matInput type="text" formControlName="ville" (change)="updateProspect2()">
                                </mat-form-field>

                                <mat-form-field [hidden]="true" class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.longitude" | translate }}</mat-label>
                                    <input matInput type="text" formControlName="longitude" (change)="updateProspect2()">
                                </mat-form-field>

                                <mat-form-field [hidden]="true" class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.latitude" | translate }}</mat-label>
                                    <input matInput type="text" formControlName="latitude" (change)="updateProspect2()">
                                </mat-form-field>
                            </div>
                            <!--Rue-->
                            <div class="col-4">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.Rue" | translate }}</mat-label>
                                    <input matInput type="text" formControlName="streetName" (change)="updateProspect2()">
                                </mat-form-field>
                            </div>
                            <!--N° rue-->
                            <div class="col-4">

                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.streetNumber" | translate }}</mat-label>
                                    <input type="text" matInput  (change)="updateProspect2()"   formControlName="streetNumber">
                                </mat-form-field>
                                <div *ngIf="prospectForm.controls['streetNumber'].invalid && (prospectForm.controls['streetNumber'].dirty || prospectForm.controls['streetNumber'].touched)">
                                    <div *ngIf="prospectForm.controls['streetNumber'].errors?.required">
                                        {{"languages.prospect.check_streetNumber" | translate }}
                                    </div>
                                </div>
                            </div>
                            <!--Code Postal-->
                            <div class="col-4">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.CodePostal" | translate }}</mat-label>
                                    <input type="text" min="0" (keypress)="numberOnly($event)" (change)="updateProspect2()" matInput placeholder="0" formControlName="CP">
                                </mat-form-field>
                            </div>
                            <!--Complément d'Addresse-->
                            <div class="col-12">

                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.fullAdress" | translate }}</mat-label>
                                    <input matInput type="text" formControlName="complement_adr" (change)="updateProspect2()">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!--Informations complémentaires-->
            <div *ngIf="prospect?.id != undefined || prospect?.id != null" class="col-xl-12 col-lg-12 prospect-card-field">
                <div class="form-group-container pt-0">
                    <div class="row d-block">
                        <!-- TAGS prospect-->
                        <shared-tags *ngIf="allowed_to_update != null && allowed_to_update != undefined" [isReadOnly]="allowed_to_update" [prospect_id]="prospect.id"></shared-tags>

                        <!--Commentaire-->
                        <div class="col-12">

                            <form [formGroup]="prospectForm">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>{{"languages.prospect.prospectComment" | translate }}</mat-label>
                                    <textarea cols="90" formControlName="description" matInput (change)="updateProspect2()"></textarea>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!--Informations bancaires-->
            <!-- <div class="col-xl-12 col-lg-12">
                <shared-information-bank [typeEntity]="typeEntity" [bankInformation]="prospect.information_bancaire" (onChangeBankInformation)="getBankInformation($event)"></shared-information-bank>
            </div> -->
        </div>
    </div>
</div>
